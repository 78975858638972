<script>
import { ref } from "vue";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import addButton from "../../../components/widgets/addButton.vue";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import Swal from "sweetalert2";
import sectionProgramCard from "./component/sectionProgramCard.vue";
import loaderProgress from "../../../components/widgets/loaderProcess.vue";

/**
 * Projects-create component
 */
export default {
  page: {
    title: "Video list",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    addButton,
    sectionProgramCard,
    loaderProgress,
  },
  data() {
    return {
      process: false,
      spinner: false,
      progress: 0,
      titre: "",
      searchQuery: "",
      searchProgram: "",
      addMovieModal: false,
      choiceProgramModal: false,
      addSectionMovieModal: false,
      addSectionProgramModal: false,
      addSectionImageModal: false,
      SectionMovieModalSelectList: false,
      SectionProgramModalSelectList: false,
      title: "Sections de programmes",
      items: [
        {
          text: "Sections",
          active: false,
        },
        {
          text: "Programmes",
          active: true,
        },
      ],
      daterange: ref(new Date()),
      videos: [],
      programs: [],
      tags: [],
      programmes: [],
      categorieList: [],
      idPrograms: [],
      idVideos: [],
      sectionPrograms: {
        title: "",
        description: "",
      },
      sectionVideos: {
        title: "",
        description: "",
      },
      sectionImage: {
        title: "",
        description: "",
      },
      sectionsVideoList: [],
      sectionsImageList: [],
      sectionsProgramsList: [],
      filesImages: [],
      listeDesSectionDesVideos: [],
    };
  },
  methods: {
    showAddSectionProgramModal() {
      this.addMovieModal = false;
      this.addSectionProgramModal = true;
      this.SectionProgramModalSelectList = false;
    },
    showSectionProgramModalSelectList() {
      this.addSectionProgramModal = false;
      this.SectionProgramModalSelectList = true;
    },

    getPrograms() {
      Api.get("/streamvodv2/api/v2/program/all")
        .then((res) => {
          this.programs = res.data.content;
          console.log(res);
        })
        .catch((error) => {
          Erreur.gestionErreur(error.message);
        });
    },
    getSectionPrograms() {
      Api.get("/streamvodv2/api/v2/program-section/all")
        .then((response) => {
          this.sectionsProgramsList = response.data.content;
          console.log(this.sectionsProgramsList);
          this.spinner = false;
        })
        .catch((error) => {
          this.spinner = false;
          Erreur.gestionErreur(error.message);
        });
    },

 
    createSectionPrograms() {
      this.process = true;
      this.SectionProgramModalSelectList = false;
      this.addMovieModal = false;
      this.process = true;
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };
      this.titre = "Ajout d'une section de programme";
      Api.post(
        `/streamvodv2/rest/v2/program-section/create`,
        {
          programIds: this.idPrograms,
          programSection: {
            description: this.sectionPrograms.description,
            isInProgram: false,
            isOnHomePage: true,
            title: this.sectionPrograms.title,
          },
        },
        { onUploadProgress }
      )
        .then(() => {
          this.process = false;
          Swal.fire("Succes!", "Section Créée", "success");
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },

  },

  computed: {
    filteredProgramsList() {
      if (!this.searchProgram) {
        return this.programs;
      }
      return this.programs.filter((program) => {
        const fullName = program.title;
        return fullName
          .toLowerCase()
          .includes(this.searchProgram.toLowerCase());
      });
    },
  },

  mounted() {
    this.spinner = true;
    this.getPrograms();
    this.getSectionPrograms();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <loaderProgress :visible="process" :progress="progress" :title="titre" />

    <div
      style="display: flex; justify-content: space-between; margin-bottom: 2rem"
    >
      <div></div>
      <addButton
        @click="showAddSectionProgramModal()"
        :text="'Créer une section'"
      ></addButton>
    </div>

    <!-- Liste des sections -->

    <div style="text-align: center; font-size: 3em" v-if="spinner == true">
      <b-spinner variant="success" role="status"></b-spinner>
    </div>
    <div
      style="text-align: center; font-size: 3em"
      v-if="spinner == false && sectionsProgramsList.length == 0"
    >
      <span style="font-size: 1.5rem">Aucune section trouvée !</span>
    </div>

    <div class="row">
      <sectionProgramCard
        v-for="section in sectionsProgramsList"
        :key="section.id"
        :section="section"
        :program-list="programs"
      ></sectionProgramCard>
    </div>

    <!-- Modal pour la création d'une section de programme -->
    <!-- =================================================================================================== -->
    <b-modal
      v-model="addSectionProgramModal"
      id="modal-xl"
      size="xl"
      title="Créer un section de programme"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <form>
          <div class="row">
            <div class="col-lg-12">
              <label class="col-form-label">Titre</label>
              <div class="col-md-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  class="form-control"
                  v-model="sectionPrograms.title"
                  placeholder="Entrez le titre de la section"
                />
              </div>
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="projectbudget" class="col-form-label"
              >Description</label
            >

            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="sectionPrograms.description"
                placeholder="Entrez la description"
              ></textarea>
            </div>
          </div>
        </form>
        <div style="display: flex; align-items: center; justify-content: end">
          <button
            @click="showSectionProgramModalSelectList()"
            class="btn c2play-primary"
          >
            Suivant
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="SectionProgramModalSelectList"
      id="modal-xl"
      size="xl"
      title="Créer une section de programme"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div>
            <button
              @click="showAddSectionProgramModal()"
              class="btn c2play-primary"
            >
              Retour
            </button>
          </div>
          <div>
            <button @click="createSectionPrograms()" class="btn c2play-primary">
              Ajouter
            </button>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-lg-6">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Rechercher une vidéo"
                style="border-radius: 8px"
                v-model="searchQuery"
              />
            </div>
          </div>
        </div>

        <br />

        <div class="table-responsive">
          <table class="table align-middle table-nowrap table-hover">
            <thead class="table-light">
              <tr>
                <th scope="col" style="width: 20px">Select</th>
                <th scope="col" style="width: 100px">Image</th>
                <th scope="col">Titre</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="program in filteredProgramsList.slice().reverse()"
                :key="program.id"
              >
                <td>
                  <b-form-checkbox
                    v-model="idPrograms"
                    :value="program.id"
                    name="checkbox-1"
                  ></b-form-checkbox>
                </td>
                <td>
                  <img
                    :src="program.imagePortrait"
                    alt="image"
                    height="60"
                    width="100"
                    style="border-radius: 7px"
                  />
                </td>
                <td>{{ program.title }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
    <!-- ================================================================================== -->

    <!-- end row -->
  </Layout>
</template>
